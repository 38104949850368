import React, { useState, useEffect, Fragment } from 'react';
import {
  useListController,
  ListView,
  TextField,
  TopToolbar,
  EmailField,
  // Filter,
  // SelectInput,
  FunctionField,
  // NumberField,
  // DateField,
  useRefresh,
} from 'react-admin';
import { IconButton, Chip } from '@material-ui/core';
import clsx from 'clsx';
import { Datagrid } from '../../components/common';
import { NewEvent } from '../../components/common/modals';
import { normalizePhone } from '../../components/common/modals/modalForms';
import { useStyles } from './payers.styles';
import { EditIcon, CustomButton, AddIcon } from '../../design';
import { getChip } from '../../utils';

const ListActions = ({ payer_facility_id }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span />
        <CustomButton
          Icon={AddIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='Insurance case manager'
          type='button'
          variant='text'
          size='small'
          onClick={() => setEventModal(true)}
        />
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='payerRepForm'
          refresh={refresh}
          payerFacilityId={parseInt(payer_facility_id)}
        />
      )}
    </Fragment>
  );
};

export const PayerContacts = ({
  record,
  id,
  permissions = [],
  isAdmin,
  ...props
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [repRecord, setRepRecord] = useState();

  useEffect(() => {
    // see comment on case_communication
    if (repRecord) {
      setEventModal(true);
    }
  }, [repRecord]);

  const handleModalClose = () => {
    setEventModal(false);
    setRepRecord(undefined);
  };

  const controllerProps = useListController({
    ...props,
    resource: 'payers-facility/payer-reps',
    filter: { id },
  });
  return (
    <div className='show-list'>
      <ListView
        empty={false}
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        actions={
          Array.isArray(permissions) &&
          (isAdmin ||
            permissions.indexOf('supervisor') > -1 ||
            permissions.indexOf('final_examiner') > -1 ||
            permissions.indexOf('contract_user') > -1) ? (
            <ListActions payer_facility_id={id} />
          ) : null
        }
      >
        <Datagrid>
          <TextField
            sortable={false}
            source='name'
            label='Name'
            headerClassName={classes.listItemLabel}
          />
          <FunctionField
            sortable={false}
            source='phone'
            label='Phone'
            headerClassName={classes.listItemLabel}
            className={classes.listItemLabel}
            render={record => {
              const { phone, phone_ext } = record;
              if (!phone) return null;
              const ext = phone_ext ? `x${phone_ext}` : '';
              return `${normalizePhone(record.phone)} ${ext}`;
            }}
          />
          <FunctionField
            sortable={false}
            source='fax'
            label='Fax'
            headerClassName={classes.listItemLabel}
            className={classes.listItemLabel}
            render={record => {
              return record.fax ? normalizePhone(record.fax) : null;
            }}
          />
          <EmailField
            sortable={false}
            source='email'
            label='Email'
            headerClassName={classes.listItemLabel}
          />
          <FunctionField
            source='is_active'
            label='Status'
            render={record => {
              const { is_active } = record;
              const status =
                !!is_active || is_active === null ? 'Active' : 'Inactive';
              return (
                <span>
                  <Chip
                    size='small'
                    label={status}
                    className={clsx(classes.chip, classes[getChip(status)])}
                  ></Chip>
                </span>
              );
            }}
          />
          <FunctionField
            source='status'
            label=''
            sortable={false}
            render={record => {
              return Array.isArray(permissions) &&
                (isAdmin ||
                  permissions.indexOf('supervisor') > -1 ||
                  permissions.indexOf('final_examiner') > -1 ||
                  permissions.indexOf('contract_user') > -1) ? (
                <IconButton
                  style={{ color: '#5C738E' }}
                  onClick={() => setRepRecord(record)}
                  size='small'
                >
                  <EditIcon />
                </IconButton>
              ) : null;
            }}
          />
        </Datagrid>
      </ListView>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={repRecord}
          form='payerRepForm'
          isEdit
          refresh={refresh}
          payerFacilityId={id}
        />
      )}
    </div>
  );
};
