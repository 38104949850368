import React, { useState, useEffect, Fragment } from 'react';
import {
  useListController,
  ListView,
  TextField,
  TopToolbar,
  // Filter,
  // SelectInput,
  FunctionField,
  // NumberField,
  // DateField,
  useRefresh,
  BooleanField,
  useUnselectAll,
} from 'react-admin';
import { IconButton } from '@material-ui/core';
import { Datagrid, Loading } from '../../components/common';
import { NewEvent } from '../../components/common/modals';
import { CustomButton } from '../../design/material-ui';
import { useStyles } from './payers.styles';
import { AddIcon, EditIcon } from '../../design';
import { currencyFormat } from '../../utils';

const ListActions = ({ record, payer_facility_id, permissions }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span />
        <CustomButton
          Icon={AddIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='Outlier'
          type='button'
          variant='text'
          size='small'
          onClick={() => setEventModal(true)}
        />
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='outliers'
          refresh={refresh}
          payerFacilityId={parseInt(payer_facility_id)}
          meta={{
            line_of_businesses: record?.line_of_businesses?.filter(
              row => row.id !== 13,
            ),
          }}
        />
      )}
    </Fragment>
  );
};

export const Outliers = ({
  record,
  id,
  permissions = [],
  isViewAdmin,
  ...props
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [carveOutRecord, setCarveOutRecord] = useState();
  useEffect(() => {
    // see comment on case_communication
    if (carveOutRecord) {
      setEventModal(true);
    }
  }, [carveOutRecord]);

  const unselectAll = useUnselectAll('payers-facility/outliers');
  useEffect(() => {
    return () => {
      unselectAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = () => {
    setEventModal(false);
    setCarveOutRecord(undefined);
  };

  const controllerProps = useListController({
    ...props,
    resource: 'payers-facility/outliers',
    filter: { id },
  });
  const { loading } = controllerProps;
  return loading ? (
    <Loading />
  ) : (
    <div className='show-list'>
      <ListView
        empty={false}
        {...controllerProps}
        exporter={false}
        bulkActionButtons={isViewAdmin ? false : undefined}
        actions={
          Array.isArray(permissions) &&
          (permissions.indexOf('admin') > -1 ||
            permissions.indexOf('supervisor') > -1 ||
            permissions.indexOf('final_examiner') > -1 ||
            permissions.indexOf('contract_user') > -1) ? (
            <ListActions payer_facility_id={id} record={record} />
          ) : null
        }
      >
        <Datagrid>
          <TextField
            source='lines_of_business'
            label='Lines of business'
            headerClassName={classes.listItemLabel}
            sortable={false}
          />
          <TextField
            source='category.category'
            label='Category'
            headerClassName={classes.listItemLabel}
            sortable={false}
          />
          <TextField
            source='services'
            label='Services'
            headerClassName={classes.listItemLabel}
            sortable={false}
          />
          <TextField
            source='rules'
            label='Rules'
            headerClassName={classes.listItemLabel}
            sortable={false}
          />
          <TextField
            source='result_text'
            label='Result'
            headerClassName={classes.listItemLabel}
          />
          <FunctionField
            source='rate'
            label='Reimbursement'
            headerClassName={classes.listItemLabel}
            render={record => {
              const { percent, rate } = record;
              return !!percent ? `${percent}%` : currencyFormat(rate);
            }}
          />
          <FunctionField
            source='billed_by_facility'
            label='Billed by facility'
            headerClassName={classes.listItemLabel}
            render={record => {
              return (
                <BooleanField
                  record={record}
                  source='billed_by_facility'
                  headerClassName={classes.listItemLabel}
                  style={{
                    color: record.billed_by_facility ? '#44C87A' : '#5C738E',
                  }}
                />
              );
            }}
          />
          <FunctionField
            source='requires_auth'
            label='Requires authorization'
            headerClassName={classes.listItemLabel}
            render={record => {
              return (
                <BooleanField
                  record={record}
                  source='requires_auth'
                  headerClassName={classes.listItemLabel}
                  style={{
                    color: record.requires_auth ? '#44C87A' : '#5C738E',
                  }}
                />
              );
            }}
          />
          <TextField
            source='notes'
            label='Notes'
            headerClassName={classes.listItemLabel}
          />
          <FunctionField
            source='status'
            label=''
            sortable={false}
            render={record => {
              return Array.isArray(permissions) &&
                (permissions.indexOf('admin') > -1 ||
                  permissions.indexOf('supervisor') > -1 ||
                  permissions.indexOf('final_examiner') > -1 ||
                  permissions.indexOf('contract_user') > -1) ? (
                <IconButton
                  style={{ color: '#5C738E' }}
                  onClick={() => setCarveOutRecord(record)}
                  size='small'
                >
                  <EditIcon />
                </IconButton>
              ) : null;
            }}
          />
        </Datagrid>
      </ListView>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={carveOutRecord}
          form='outliers'
          isEdit
          refresh={refresh}
          payerFacilityId={id}
          meta={{
            line_of_businesses: record?.line_of_businesses?.filter(
              row => row.id !== 13,
            ),
          }}
        />
      )}
    </div>
  );
};
