import React from 'react';
import { required } from 'react-admin';
import {
  FormTextField,
  SelectComponent,
  ReferenceInput,
  composeValidators,
  validateDate,
} from '../modalForms';
import { constants } from '../../../../utils';
import clsx from 'clsx';
const { turnaround_times } = constants;

export const AppealFormFields = ({
  classes,
  customOnChange,
  record,
  level,
  isEdit = false,
}) => (
  <div>
    <div className={clsx(classes.inputContainerWrap)}>
      <FormTextField
        name={`posted_date${level}`}
        validate={composeValidators([required(), validateDate()])}
        required
        customOnChange={customOnChange}
        type='date'
        label='Posted to Appeals team'
        disabled={!!record[`appeal_type_id${level}`] && !isEdit}
      />
      <FormTextField
        customOnChange={customOnChange}
        label='Appeal ID'
        name={`appeal_id${level}`}
        disabled={!!record[`appeal_type_id${level}`] && !isEdit}
      />
    </div>
    <div className={clsx(classes.inputContainerWrap)}>
      <FormTextField
        name={`date_sent${level}`}
        validate={composeValidators([validateDate()])}
        customOnChange={customOnChange}
        type='date'
        label='Clinicals sent'
        disabled={!!record[`appeal_type_id${level}`] && !isEdit}
      />
      <SelectComponent
        customOnChange={customOnChange}
        label='Turnaround time'
        name={`turnaround_time${level}`}
        choices={turnaround_times}
        disabled={!!record[`appeal_type_id${level}`] && !isEdit}
      />
    </div>
    <div className={clsx(classes.inputContainerWrap)}>
      <FormTextField
        name={`outcome_date${level}`}
        validate={composeValidators([validateDate()])}
        customOnChange={customOnChange}
        type='date'
        label='Appeal outcome date'
        disabled={!!record[`appeal_type_id${level}`] && !isEdit}
      />
      <ReferenceInput
        customOnChange={customOnChange}
        label='Appeal outcome'
        validate={required()}
        required
        name={`appeal_status_id${level}`}
        reference='appeal-statuses/list'
        disabled={!!record[`appeal_type_id${level}`] && !isEdit}
      />
    </div>
    <div className={clsx(classes.inputContainerWrap)}>
      <FormTextField
        label='Notes'
        name={`appeal_note${level}`}
        multiline
        customOnChange={customOnChange}
        disabled={!!record[`appeal_type_id${level}`] && !isEdit}
      />
      <FormTextField
        label='Appeal Outcome Rationale'
        name={`outcome_rationale${level}`}
        multiline
        customOnChange={customOnChange}
        disabled={!!record[`appeal_type_id${level}`] && !isEdit}
      />
    </div>
  </div>
);
