import { Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import {
  FunctionField,
  ReferenceField,
  Show,
  ShowView,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { DateField, TrackUser } from '../../components/common';
import { NotesModal } from '../../components/common/modals';
import { useShowController } from '../../hooks';
import { ResidentShowActions, ResidentShowFields } from '../../resources';
import { constants, limitStringSize } from '../../utils';
import { TimelineShowActions } from './TimelineShowActions';

const { eventTypes, appeal_type } = constants;

export const CaseAppealShow = ({
  closeAside,
  classes,
  residentId,
  caseId,
  setRefreshTimeline,
  isViewAdmin,
  ...props
}) => {
  const { resource, id, ...rest } = props;
  const showProps = useShowController({ ...props });
  const [showNotesModal, setShowNotesModal] = useState(null);
  const maxNotesLength = 160;
  const { record = {} } = showProps;
  return (
    <div className='row-wrapper'>
      <ShowView
        actions={
          <TimelineShowActions
            closeAside={closeAside}
            title='Appeal'
            resourceRecord={record}
            eventForm='appealEditForm'
            eventId={record.id}
            eventType='Appeal'
            eventName={eventTypes.APPEAL_NAME}
            caseId={caseId}
            setRefreshTimeline={setRefreshTimeline}
            data={record}
            isViewAdmin={isViewAdmin}
          />
        }
        {...showProps}
      >
        <SimpleShowLayout className={clsx(classes.showLayout)}>
          <TextField
            source='level'
            label='Level'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='posted_date'
            className={classes.showItem}
            label='Posted to Team'
            textAlign='right'
          />
          <FunctionField
            source='turnaround_time'
            label='Turnaround'
            textAlign='right'
            className={clsx(classes.showItem)}
            render={record => {
              const formatted = record.turnaround_time?.replace(/_/g, ' ');
              return <span>{formatted}</span>;
            }}
          />
          <ReferenceField
            label='Appeal type'
            source='appeal_type_id'
            reference='appeal-types/list'
            textAlign='right'
            className={classes.showItem}
            link={false}
          >
            <TextField
              source='name'
              textAlign='right'
              className={classes.showItem}
            />
          </ReferenceField>
          <TextField
            source='appeal_id'
            label='Appeal ID'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='date_sent'
            className={classes.showItem}
            label='Clinicals sent'
            textAlign='right'
          />
          <DateField
            source='outcome_date'
            className={classes.showItem}
            label='Outcome Date'
            textAlign='right'
          />
          <ReferenceField
            label='Appeal status'
            source='appeal_status_id'
            reference='appeal-statuses/list'
            textAlign='right'
            className={classes.showItem}
            link={false}
          >
            <TextField
              source='name'
              textAlign='right'
              className={classes.showItem}
            />
          </ReferenceField>

          {record.appeal_type_id === appeal_type.OTHER ? (
            <TextField
              source='reason'
              label='Reason for appeal'
              textAlign='right'
              className={classes.showItem}
            />
          ) : (
            <div className={classes.showItem} />
          )}
          <FunctionField
            source='appeal_note'
            label='Note'
            textAlign='right'
            className={clsx(classes.showItem, classes.commentField)}
            render={record => {
              const { appeal_note } = record;
              const truncated = limitStringSize(
                appeal_note,
                maxNotesLength,
                true,
              );
              return appeal_note && appeal_note.length > maxNotesLength ? (
                <span>
                  {truncated}{' '}
                  <Typography
                    color='primary'
                    component='span'
                    className={classes.showMore}
                    onClick={() => setShowNotesModal(appeal_note)}
                  >
                    Show more
                  </Typography>
                </span>
              ) : (
                truncated
              );
            }}
          />
          <FunctionField
            source='outcome_rationale'
            label='Outcome Rationale'
            textAlign='right'
            className={clsx(classes.showItem, classes.commentField)}
            render={record => {
              const { outcome_rationale } = record;
              const truncated = limitStringSize(
                outcome_rationale,
                maxNotesLength,
                true,
              );
              return outcome_rationale &&
                outcome_rationale.length > maxNotesLength ? (
                <span>
                  {truncated}{' '}
                  <Typography
                    color='primary'
                    component='span'
                    className={classes.showMore}
                    onClick={() => setShowNotesModal(outcome_rationale)}
                  >
                    Show more
                  </Typography>
                </span>
              ) : (
                truncated
              );
            }}
          />
          {showNotesModal && (
            <NotesModal
              open={!!showNotesModal}
              handleClose={() => setShowNotesModal(null)}
              note={showNotesModal}
            />
          )}
          <FunctionField
            source='appealCreatedBy.name'
            label='Created by'
            className={classes.showItem}
            render={record => {
              const { appealCreatedBy, created_at } = record;
              return (
                <TrackUser userField={appealCreatedBy} timestamp={created_at} />
              );
            }}
          />
          <FunctionField
            source='appealUpdatedBy.name'
            label='Last modified'
            className={classes.showItem}
            render={record => {
              const { appealUpdatedBy, updated_at } = record;
              return (
                <TrackUser userField={appealUpdatedBy} timestamp={updated_at} />
              );
            }}
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
          {/* <div className={classes.showItem} /> */}
        </SimpleShowLayout>
      </ShowView>
      <Divider className={classes.residentDivider} />
      <p className={classes.casesTitle} style={{ paddingLeft: 16 }}>
        Resident info
      </p>
      <Show
        actions={
          <ResidentShowActions
            closeAside={closeAside}
            isResidentPage={false}
            setRefreshTimeline={setRefreshTimeline}
            isViewAdmin={isViewAdmin}
          />
        }
        {...rest}
        resource='residents'
        id={residentId}
      >
        <ResidentShowFields classes={classes} includeCases />
      </Show>
    </div>
  );
};
