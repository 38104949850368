import React, { useState, useEffect, Fragment } from 'react';

import clsx from 'clsx';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
  usePermissions,
  cacheDataProviderProxy,
} from 'react-admin';
import { DialogActions, DialogContent } from '@material-ui/core';
import {
  ReferenceInput,
  FormTextField,
  AppealFormFields,
  handleNumbers,
  getDateInputValue,
  composeValidators,
  minReasonLength,
} from '../modalForms';
import { DeleteRecord } from '../../../wrappers';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';
import { constants } from '../../../../utils';
const { appeal_type } = constants;

export const AppealEditForm = ({
  caseId,
  record = {},
  // isEdit,
  handleClose,
  isPlannedDischarge,
  closeAside,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (record) {
      const { id, ...rest } = record;
      setFormData(f => ({
        ...f,
        ...rest,
      }));
    }
  }, [record]);

  const onSubmit = value => {
    return dataProvider
      .update('appeals', {
        id: record.id,
        data: { ...value },
        previousData: { ...record },
      })
      .then(({ data }) => {
        notify('form.updated');
        handleClose();
        refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <ReferenceInput
                    reference='appeal-types/list'
                    customOnChange={customOnChange}
                    validate={required()}
                    required
                    name='appeal_type_id'
                    label='Appeal Type'
                    // disabled={!!caseId}
                    style={{ marginRight: 0 }}
                  />
                </div>
                {formData.appeal_type_id === appeal_type.OTHER && (
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      name='reason'
                      validate={composeValidators([
                        required(),
                        minReasonLength(),
                      ])}
                      required
                      customOnChange={customOnChange}
                      label='Reason for appeal'
                      style={{ marginRight: 0 }}
                    />
                  </div>
                )}
                <AppealFormFields
                  classes={classes}
                  customOnChange={customOnChange}
                  record={record}
                  level=''
                  isEdit
                />
                {/* <FileInput
                  label='Appeal Attachments'
                  title='title'
                  name='attachments'
                  setFormData={setFormData}
                  formData={formData}
                /> */}
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16, justifyContent: 'space-between' }}
              >
                {userPermissions.indexOf('admin') > -1 ? (
                  <DeleteRecord
                    handleClose={handleClose}
                    resource='appeals'
                    id={record.id}
                    record={record}
                    onClick={
                      typeof closeAside === 'function' ? closeAside : undefined
                    }
                  />
                ) : (
                  <span />
                )}
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
