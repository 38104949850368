let _ENV = "development"
let _API_URL = "/api/V1"
let _DOWNLOAD_URL = "http://localhost:10010/download"
if (window.location.host === "dev.mcomanager.com") {
    _ENV = "staging";
    _API_URL = "https://cms-api.bitbean.dev/api/V1"
    _DOWNLOAD_URL = "https://cms-api.bitbean.dev/download"
} else if (window.location.host === "cmsauth.bitbean.dev") {
    _ENV = "staging";
    _API_URL = "https://cmsauth.bitbean.dev/api/V1"
    _DOWNLOAD_URL = "https://cmsauth.bitbean.dev/download"
} else if (window.location.host === "www.mcomanager.com") {
    _ENV = "production"
    _API_URL = "https://api.mcomanager.com/api/V1"
    _DOWNLOAD_URL = "https://api.mcomanager.com/download"
} else if (window.location.host === "mcomanager.cmsauths.com") {
    _ENV = "production"
    _API_URL = "https://mcomanager.cmsauths.com/api/V1"
    _DOWNLOAD_URL = "https://mcomanager.cmsauths.com/download"
}

export const API_URL = _API_URL
export const ENV = _ENV
export const DOWNLOAD_URL = _DOWNLOAD_URL